import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/index';
import { VuePlugin } from 'vuera';
import VueAnalytics from 'vue-analytics';
import axios from 'axios';
import './styles/main.scss';
import { EnvironmentProviderPlugin, Environment } from './plugins/environmentProviderPlugin';

import { centralizedAuth } from '@cimpress/simple-auth-wrapper';
import AuthConfig from './lib/authConfig';
import AuthManager from './lib/authManager';
import UserManager from './lib/userManager';
import * as Fullstory from './lib/fullstory';
// import the platform client from the source code instaed of the whole library
// reason is, that it currently depends on the aws-sdk in another file, which makes
// the dependency very large
// eslint-disable-next-line node/no-unpublished-import
import PlatformClient from '../node_modules/microservice-utilities/src/platformClient';
import { commands } from './lib/vueAnalyticsCommands';

Vue.config.productionTip = false;
Vue.use(VuePlugin);
Vue.use(EnvironmentProviderPlugin);
Vue.use(VueAnalytics, {
  id: 'UA-154580495-1',
  router,
  debug: {
    enabled: Environment.getEnvironment() !== 'production', // whether console statements are written
    sendHitTask: Environment.getEnvironment() === 'production' // whether the hits are sent to GA
  },
  commands
});

const baseURL = 'https://api.data-discovery.cimpress.io';

const logger = {
  // eslint-disable-next-line no-console
  log: console.log
};

let userManager = new UserManager();
let authConfig = new AuthConfig();
let authWrapper = new centralizedAuth({
  clientID: authConfig.clientId,
  redirectRoute: '/'
});
authWrapper.on('tokenExpired', async () => {
  const nextUri = window.location.pathname || '/';
  await authWrapper.login({ nextUri, forceLogin: true });
});

async function init() {
  let authManager = new AuthManager(userManager, authWrapper, logger);
  await authManager.login();
  // Setting up reporting...
  const profile = userManager.getUserData();
  if (profile && window.location.hostname !== 'localhost') {
    Fullstory.init();
    Fullstory.setProfile({
      sub: profile.user_id,
      name: profile.name,
      email: profile.app_metadata.canonical_id,
      account: profile.app_metadata.account.id
    });
  }

  // Setting axios timeout(ms) which occurs when server takes long time to respond
  axios.defaults.timeout = 45000;
  const axiosPlatformClient = axios.create({ baseURL });
  let platformClient = new PlatformClient(() => {}, () => authManager.getAccessToken(), { client: axiosPlatformClient });
  new Vue({
    router,
    store,
    provide: {
      authManager,
      userManager,
      platformClient
    },
    render: h => h(App)
  }).$mount('#app');
}

init();
